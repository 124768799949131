import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { containerMixin, responsiveQuery } from '../components/Layouts/LayoutWrap/GlobalStyles';
import MainLayout from '../components/Layouts/MainLayout/MainLayout';
import Seo from '../components/Misc/Seo/Seo';
import { Heading, P, HR, SectionHeader } from '../components/Misc/Typogrpahy/Typography';
import { Services as ServicesSection } from './index';
import { FullWidthSlider } from '../components/Slider/Slider';
import { SquareGridBackground } from '../components/Misc/Decorative/Decorative';

const SectionContent = styled.div`
  ${containerMixin};
  position: relative;
  z-index: 10;
  &:last-of-type {
    padding-bottom: 4rem;
  }
  ${SectionHeader} {
    max-width: calc(100% - calc(2 * var(--grid-cut)));
    margin: -6rem auto 8rem;
    padding-top: 5rem;
    z-index: 2;
    background-color: #fff;
    ${responsiveQuery.laptop`
      padding: 5rem 2rem 0;
      max-width: 100%;
    `};
  }
`;

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesPageQuery {
      site {
        siteMetadata {
          pages {
            services {
              metaTitle
              heroSlider {
                image
              }
              title
              description
              services {
                title
                icon
                items {
                  description
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    metaTitle,
    heroSlider,
    title,
    description,
    services,
  } = data.site.siteMetadata.pages.services;
  return (
    <MainLayout>
      <Seo title={metaTitle} />
      <FullWidthSlider data={heroSlider} />
      <SectionContent>
        <SectionHeader>
          <Heading as='h2'>{title}</Heading>
          <HR />
          <P>{description}</P>
        </SectionHeader>
      </SectionContent>
      <SquareGridBackground fadein='true'>
        <SectionContent>
          <ServicesSection services={services} />
        </SectionContent>
      </SquareGridBackground>
    </MainLayout>
  );
};

export default Services;
